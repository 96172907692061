<template lang="pug">
settings-page(title="Управление тегами")
  .tags-settings
    settings-panel.fit-height
      template(#header) Теги
      tags-management(@tag:delete="onDeleteTag")
    settings-panel.fit-height(v-if="isTmManager")
      template(#header) Удаленные теги
      deleted-tags-management(ref="refTags")
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import SettingsPage from "~/components/pages/settings/common/SettingsPage.vue";
import SettingsPanel from "~/components/pages/settings/common/SettingsPanel.vue";
import TagsManagement from "~/views/settings/tags/TagsManagement.vue";
import DeletedTagsManagement from "~/views/settings/tags/DeletedTagsManagement.vue";

export default defineComponent({
  name: "TagsSettings",
  components: {
    SettingsPanel,
    SettingsPage,
    TagsManagement,
    DeletedTagsManagement,
  },
  setup() {

    const refTags = ref();
    const { isTmManager, roleReadOnly, roleReadWriteLocal } = useUserAccess();

    function onDeleteTag(tag: any) {
      if (refTags.value) refTags.value.pushDeletedTag(tag)
    }

    return {
      refTags,
      isTmManager,
      roleReadOnly,
      roleReadWriteLocal,
      onDeleteTag,
    }
  }
})
</script>

<style scoped lang="scss">
.tags-settings {
  display: grid;
  grid-template-columns: 600px 1fr;
  gap: 40px;
}

.fit-height {
  height: fit-content;
}
</style>
